.loading-spinner {
  position: absolute;
  /* child */
  top: 50%;
  left: 50%;
  margin-left: -39px;
  /* half of width*/
  margin-top: -39px;
  /* half of height */
}

.App .top-bar {
  background: #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
  padding-right: 20px;
  padding-left: 12px;
  padding-bottom: 12px;
}

.App .loading-spinner {
  position: absolute;
  /* child */
  top: 50%;
  left: 50%;
  margin-left: -39px;
  /* half of width*/
  margin-top: -39px;
  /* half of height */
}


.App .side-nav .logo {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  margin: 10px 0px 20px 0px;
  text-align: center;
  white-space: nowrap;
}

.App .side-nav .logo img {
  width: 50px;
}

.App .side-nav .logo h2 {
  display: inline-block;
  vertical-align: middle;
  color: #302f2f;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
  margin: 2px;
  overflow: hidden;
}

.App .side-nav ul.ant-menu,
.App .side-nav .ant-layout-sider-trigger {
  background-color: #F5F5F5;
}

.App .side-nav ul.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

.App .side-nav.ant-layout-sider-collapsed .logo {
  padding-left: 4px;
}

.App .side-nav.ant-layout-sider-collapsed h2 {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}



.App .login-bg {
  height: 100vh;
  background-size: cover;
}

.App .login-box {
  width: 200px;
  height: 200px;
  border-radius: 4px;
  text-align: center;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.App .login-box img {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.App .login-box .ant-btn {
  display: block;
  margin: auto;
}

.App .ant-layout-content {
  background-color: #F5F5F5;
  padding: 10px;
  min-height: 90vh;
}

.App .pagingation {
  float: right;
}

.App .pagingation button {
  border-color: transparent;
  background-color: transparent;
  margin: 16px;
}

.App .ant-card {
  border-radius: 5px;
  padding: 5px;
}

.App .ant-card .ant-card-head {
  margin: 0px -5px;
  padding-left: 29px;
}

.App .ant-card.detail-view {
  color: #F5F5F5;
  margin-bottom: 20px;
}

.App .ant-card.detail-view .ant-card-body {
  line-height: 2;
}


.App span.item-label {
  color: #777;
}

.App div.photo-grid {
  display: inline-block;
}

.App div.photo-grid img {
  border-radius: 5px;
  margin: 5px 5px 5px 0px;
}

.App .tool-container {
  text-align: center;
}

.App .tool-container .tool-icon {
  font-size: 30px;
  margin: 10px;
}

.App .tool-actions {
  margin: 4px 0px;
  text-align: left;
  list-style: none;
}

.App .tool-actions li {
  padding: 4px 0px;
}

.App .tool-actions li .anticon {
  margin-right: 6px;
}

.App .tool-actions li span.processing {
  font-weight: bold;
}

.App .tool-actions li span.waiting {
  opacity: 0.4;
}

.App .ant-btn,
.App .ant-card-head-title,
.App h1,
.App h2 {
  font-family: "Montserrat", sans-serif;
}


.ant-layout .ant-layout-header {
  background: #F5F5F5;
  padding: 10px;
  height: inherit;
  line-height: inherit;
}

.ant-layout .ant-layout-footer {
  /* position: fixed; */
  text-align: center;
  background: #F5F5F5;
  padding-bottom: 0;
}

div.img-select {
  text-align: center;
  padding-bottom: 5px;
  width: 150px;
  border: 1px solid #F5F5F5;
  border-radius: 5px;
}

div.img-select:hover {
  cursor: pointer;
}

div.img-select img {
  margin-bottom: 4px;
}

.ant-modal-body form {
  margin-top: 24px;
}

.fa {
  margin-right: 8px;
}

.App .ant-table-tbody {
  background: #F5F5F5;
}

.ant-table-thead>tr>th:not(:first-child),
.ant-table-tbody>tr>td:not(:first-child) {
  text-align: center;
}



#commit-hash {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 11px;
  padding: 2px 4px;
  border: solid 1px #ccc;
  border-radius: 4px;
  margin: 0px 6px;
}

.active {
  color: rgb(67, 67, 99);
  text-decoration: underline;
}

.site-layout {
  width: 100%;
}

.site-layout-small {
  margin-left: 80px;
}

.site-layout-large {
  margin-left: 200px;
}

.App .ant-card {
  background-color: #F5F5F5;
}

.App .ant-card .ant-card-actions {
  background-color: #F5F5F5;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 6px solid red;
}

::-webkit-scrollbar {
  width: 2px;
  /* Remove scrollbar space */
  background: transparent;

  /* Optional: just make scrollbar invisible */
}

.clickable-row {
  cursor: pointer;
}

/* External or inline styling */
.responsive-embed-container {
  position: relative;
  width: 100%;
  height: 90vh;
  background-color: #f0f0f0; /* Optional background color */
}

.responsive-embed-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
